@import "../smeg/theming/variables";
@import "theming/variables";
@import "../shared/main";
@import "theming/categoriesColors";

.instrument {

    /* DEFAULT COLOR */
    .cmp_heroBanner.tpl_blocks.bg-darker,
    .cmp_heroBanner.tpl_blocks .bg-darker,
    .cmp_heroBanner.tpl_textFocus.bg-default,
    .base-layout__left-right .default-section,
    .default-section,
    .black-section .eform-component .form-control {
        background-color: $white;
        color: $black;
    }

    /* END DEFAULT COLOR */

    /* BASE */

    .base-layout .base-layout__left-right {
        background-color: $white;
    }

    .how-to-connect-component {

        p,
        a {
            color: #a0a0a0;
        }
    }

    .title {
        font-weight: 600 !important;
    }

    /* CTA */
    .action-link {
        display: inline-block;
        padding: 15px 40px;
        width: auto;
        color: $white;
        background-color: $black;

        &:hover {
            color: $white;
        }

        &:before {
            display: none;
        }
    }

    .white-section {
        .action-link {
            background-color: $black;
            color: $white;
        }
    }

    .black-section {
        .action-link {
            background-color: $white;
            color: $black;
        }
    }

    .cta_box {
        margin-bottom: 1rem;
    }

    .products-manager-page .white-section .action-link,
    .products-manager-page .white-section a {
        color: $black;
    }

    //On tab download on product detail layout cta like domestic
    .product-details-content {
        .downloadTab {
            .cta_box {
                a.action-link {
                    background-color: transparent;
                    color: $t-color-primary;
                    position: relative;
                    &:before {
                        content: "";
                        display: inline-block;
                    }
                    &:hover {
                        text-decoration: none;

                        &:before {
                            margin-right: 30px;
                            width: 50px;
                            transition: all 1s ease-in-out;
                        }
                    }
                }
            }
        }
    }

    /* END CTA*/

    /* CTA SHOW MORE */
    .cmp_heroBanner.tpl_textFocus {
        .textBlock {
            .text {
                display: contents;
            }

            &.collapsed {
                .text {
                    p:first-of-type {
                        display: inline;
                        margin-right: 1rem;
                    }
                }
            }

            &.expanded {
                .text {
                    p {
                        display: block;
                    }

                    p:last-of-type {
                        display: inline;
                        margin-right: 1rem;
                    }
                }
            }

            .btn__showMore {
                display: inline;

                .btn__showMore__label {
                    padding: 0;
                    background: unset;
                    color: black;
                    text-transform: inherit;

                    &:before {
                        float: right;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f107";
                        border: 1px solid transparent
                    }

                    &.hide {
                        &:before {
                            transform: rotate(180deg);
                            display: inline;
                            width: 10px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    /*END CTA SHOW MORE */

    /* FORMS */
    .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
        color: $black;
    }

    input#sku-download {
        padding-left: 10px;
        padding-right: 10px;
    }

    .eform-component .eform-component__block .eforms-buttons button {
        background-color: $black;
        color: $white;
    }

    /*END FORMS*/

    /*MENU*/
    .header-nav,
    .main-nav,
    .header__mouseOver .header__secondaryMenu,
    .burgerMenu,
    .mobile-menu .default-section,
    .searchLayer {
        background-color: $color-primary--menu;
        color: $black;
    }

    .main-nav .nav-item {
        &:first-of-type {
            .secondNav__aesthetics .thirdLevelNavbar ul {
                column-count: 3;
                column-gap: 200px;
            }
        }
    }

    .header_btn-search,
    .search-bar__submit {
        .icon {
        background-image: url('#{$images}/search-icon--black.svg');
        background-size: contain;
        }
    }

    /* SITEMAP */
    .sitemap__component {
        .sitemap__thirdlevel {
            grid-template-columns: auto;
            max-width: 100%;
        }
    }

    .sitemap__category--inspirations {
        .sitemap__secondLevelMenu {
            text-transform: uppercase;
            line-height: 70px;
            font-weight: 700;
        }
    }


    .main-nav .nav-first {
        font-weight: bold;
    }

    .header__mouseOver.open .nav-first {
        border-bottom: 1px solid $black;
    }

    .main-nav .nav-second {
        text-transform: inherit;
        font-size: 20px;
        font-weight: bold;
    }

    .nav-third {
        a {
            border-bottom: none;
            color: black;
            display: inline-block;
            height: 30px;
            font-weight: bold;
            min-width: max-content;
        }
    }

    .secondNav {
        justify-content: space-evenly;
        width: 90%;
    }

    .secondNav__solutions {
        width: 70%;

        .nav-link.nav-second {
            font-size: 2rem;
        }

        .thirdLevelNavbar {
            ul {
                column-count: 1;
            }
        }

        .nav-third {
            max-width: 300px;
        }
    }

    .secondNav__products {
        .thirdLevelNavbar ul {
            column-count: 1;
        }
    }

    /*END MENU*/

    /* COMPONENTS */
    .cmp_cards {
        &.tpl_twoColumn {
            .item {
                &:hover {
                    background-color: inherit;

                    .image {
                        transition: none;
                        transform: translateY(0px);
                    }
                }
            }
        }
    }

    .cmp_generalText.bg-default {
        .action-link.action-link--white {
            //color: $black;
        }
    }

    /* END COMPONENTS */

    .breadcrumb--white {
        .breadcrumb-item {
            a {
                color: $white;
            }

            &.active,
            a:disabled,
            a[disabled],
            a:not([href]):not([tabindex]) {
                color: $white;
            }
        }
    }

    //BREADCRUMBS
    .breadcrumb {
        .breadcrumb-item {

            //remove duplicate breadcrumbs
            &.removeThisBreadcrumb {
                display: none;
            }

            &.removeNextBreadcrumb~li:not(:last-child) {
                display: none;
            }
        }
    }

    .sitemap__category--default,
    .sitemap__category--inspirations{
        max-width: fit-content;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    /* NEWS */
    .cmp_newsList,
    .js-storyItem.stories-grid__item {
        >a:not(.storyItem__borderBlock--light ){
            color:white;
        }
        >a:has(.storyItem__borderBlock--light ){
            color:black;
        }
        .storyItem__borderBlock {
            &:not(.storyItem__borderBlock--light ) {
                .storyItem__textBlock {
                    color: white;
                }
            }
        }
    }
}

.foodservice {
    /* NEWS */
    .cmp_newsList,
    .js-storyItem.stories-grid__item {
        >a:not(.storyItem__borderBlock--light ){
            color:white;
        }
        >a:has(.storyItem__borderBlock--light ){
            color:black;
        }
        .storyItem__borderBlock {
            &:not(.storyItem__borderBlock--light ) {
                .storyItem__textBlock {
                    color: white;
                }
            }
        }
    }
}