$color-medial:#6fcbaf;
$color-dental:#dd6068;
$color-laboratory:#e79f3d;
$color-pharmaceutical:#062955;

.instrument {
    .cmp_productManager {

        &.medical{
            @include instrumentCategoryColor($color-medial);
        }
        &.dental{
            @include instrumentCategoryColor($color-dental);
        }
        &.laboratory {
            @include instrumentCategoryColor($color-laboratory);
        }
        &.pharmaceutical{
            @include instrumentCategoryColor($color-pharmaceutical);
        }

        /* exceptions */

        .cmp_heroBanner.tpl_textFocus .text{
            color: inherit;
        }
    }

    .tpl_square{
        .slide{
            border:10px solid $bg-color-secondary;
            color:$t-color-primary;
            background-color: $light-white;
        }
    }
}

